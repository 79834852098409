<style lang="less" scoped>
/deep/ .ant-form {
  > .ant-row {
    padding-left: 20px;
    /deep/ .ant-form-item-label {
      width: 120px;
      text-align: left;
    }
  }
  .ant-form-item-control{
    height: 52px;
  }
  .ant-form-item{
    margin-bottom: 0;
  }
  .ant-form-item-label{
  height: 60px;
 }
}
.support {
  /deep/ .ant-form-item-label {
    width: 200px !important;
    text-align: left;
  }
    .ant-row {
    padding-left: 40% !important;
    }
}
.remark{
  margin-left:10px;color:rgba(0, 0, 0, 0.25);
}
</style>
<template>
<div>
 
  <a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
    <a-form :form="pageForm" @submit="pageSubmit">
      <a-row>
        <a-col :xl="10" :lg="24" :md="24" :sm="24">
          <a-form-item label="装卸费单价" :labelCol="{ lg: { span: 8 }, sm: { span: 8 } }">
            <a-input-number
              name="handlingFee"
              :min="0"
              :step="0.01"
              v-decorator="['handlingFee', pageFormRules.handlingFee]"
            />
            <a-button disabled class="percentage"> 元</a-button>
            <span class="remark">公斤*单价</span>
          </a-form-item>
        </a-col>       
      </a-row>
      <a-row>
            <a-col :xl="10" :lg="24" :md="24" :sm="24">
                <a-form-item label="分拣费单价" :labelCol="{ lg: { span: 8 }, sm: { span: 8 } }">
                    <a-input-number
                    name="sortingFee"
                    :min="0"
                    :step="0.01"
                    v-decorator="['sortingFee', pageFormRules.sortingFee]"
                    />
                    <a-button disabled class="percentage"> 元</a-button>
                    <span class="remark">件/箱*单价</span>
                </a-form-item>
            </a-col>      
      </a-row>

      <a-row>
        <a-col :xl="10" :lg="24" :md="24" :sm="24">
          <a-form-item label="托盘费单价" :labelCol="{ lg: { span: 8 }, sm: { span: 8 } }">
            <a-input-number
              name="palletFee"
              :min="0"
              :step="0.01"
              v-decorator="['palletFee', pageFormRules.palletFee]"
            />
            <a-button disabled class="percentage"> 元</a-button>
            <span class="remark">托盘个数*单价</span>
          </a-form-item>
        </a-col>
       
      </a-row>

      <a-row>
        <a-col :xl="10" :lg="24" :md="24" :sm="24" style="width:50%;">
          <a-form-item label="托盘个数计算规则" :labelCol="{ lg: { span: 8 }, sm: { span: 8 } }">
            <a-input-number
              name="palletNumRule"
              :min="0"
              :step="0.01"
              v-decorator="['palletNumRule', pageFormRules.palletNumRule]"
            />
            <a-button disabled class="percentage">M³</a-button>
            <span class="remark" >（例如每0.03M³需要一个托盘）</span>
          </a-form-item>
        </a-col>
       
      </a-row>

     
      <a-form-item :wrapperCol="{ span: 18 }">
        <a-input name="id" type="hidden" style="display: none" v-decorator="['id', pageFormRules.id]" />
        <div style="text-align: left;width:100%;">
          <a-button type="primary" htmlType="submit">保存</a-button>
        </div>
      </a-form-item>
    </a-form>
  </a-card>
</div>
</template>

<script>
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { costInfo, costInsert, costUpdate } from '@/api/quotation'
import Vue from 'vue'

const pageFormRules = {
  id: { initialValue: null },
  handlingFee: { initialValue: 0, rules: [{ required: true, message: '请输入装卸费单价' }] },
  sortingFee: { initialValue: 0, rules: [{ required: true, message: '请输入分拣费单价' }] },
  palletFee: { initialValue: 0, rules: [{ required: true, message: '请输入托盘费单价' }] },  
  palletNumRule: { initialValue: 0, rules: [{ required: true, message: '请输入托盘数量规则' }] }  

  
}

export default {
  name: 'DeliveryFee',
  data() {
    return {
      pageForm: this.$form.createForm(this),
      pageFormRules: pageFormRules,
      costFile: '',
    }
  },
  mounted() {
   this.pageLoad();
  },
  methods: {
    pageLoad(){
           let that = this
      costInfo({})
        .then((res) => {
          if (0 == res.code) {
            const record = res.result
            console.log('record',record)
            if (typeof(record) != 'object') {
              return;
            }
            that.$nextTick(() => {
              that.pageForm.setFieldsValue({
                id: record.id,      
                handlingFee: record.handlingFee,
                sortingFee: record.sortingFee,
                palletFee: record.palletFee,
                palletNumRule: record.palletNumRule,        
              })
            })
          }
        })
        .catch((e) => {
          console.info(e)
          this.$message.error('数据加载失败，请稍后重试')
        })
    },
    pageSubmit(e) {
      e.preventDefault()
      let that = this
      this.pageForm.validateFields((err, values) => {
        if (!err) {
          if (values.id) {
            that.handleUpdate(values)
          } else {
            that.handleInsert(values)
          }
        }
      })
    },
    handleInsert(parameter) { 
      let that = this
      costInsert(parameter)
        .then((res) => {
          if (0 == res.code) {
            this.$success({ title: '保存成功' })
            that.pageLoad()
          } else {
            this.$error({ title: '保存失败' })
          }
        })
        .catch((e) => {
          console.info(e)
          this.$error({ title: '保存失败，请稍后重试' })
        })
    },
    handleUpdate(parameter) {
      let that = this
      costUpdate(parameter)
        .then((res) => {
          if (0 == res.code) {
            this.$success({ title: '保存成功' })
            that.pageLoad()
          } else {
            this.$error({ title: '保存失败' })
          }
        })
        .catch((e) => {
          console.info(e)
          this.$error({ title: '保存失败，请稍后重试' })
        })
    }           
  },
}
</script>
<style lang="less" scoped>
  .ant-form{
   /deep/ .ant-form-item-children{
      display: flex;
      .ant-input-number{
        border-radius: 5px 0 0 5px;
      }
      .percentage{
        border-radius:0 5px 5px 0;
        border-left: 0;
      }
    }
  }
  /deep/ .ant-form-item-label{
    width: 200px;
    text-align: left !important;
}
.ant-form-item{
  display: flex;
  align-items: center;
}
</style>